import "./src/styles/global.css";
import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby";

const onRedirectCallback = (appState) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || "/", { replace: true });
};

const AUTH0_DOMAIN = process.env.GATSBY_AUTH0_DOMAIN;
const AUTH0_CLIENTID = process.env.GATSBY_AUTH0_CLIENTID;

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENTID}
      authorizationParams={{ redirect_uri: window.location.origin }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {element}
    </Auth0Provider>
  );
};
